
  import 'jquery/dist/jquery.min.js';
  import "datatables.net-dt/js/dataTables.dataTables";
  import {
    reactive,
    onMounted,
    computed,
    ref,
  } from "vue";
  import {
    setCurrentPageTitle
  } from "@/core/helpers/breadcrumb";
  import {
    useRoute
  } from 'vue-router';
  import {
    api_url
  } from '@/ayar';
  import moment from 'moment';
  import post from "@/core/functions/post";
  import store from "@/store";
  import { l } from '@/core/helpers/lang';
  import NumberFormat from "@/core/functions/NumberFormat";

  export default ({

    name: "details",

    setup() {

      const user = ref(0);
      const order = ref(1);
      const order_earnings = ref(2);
      const cart = ref(3)

      const state = reactive({
        user,
        order,
        order_earnings,
        cart
      });

      const route = useRoute()
      onMounted(() => {
        post(api_url + `order/` + route.params.id, {}).then(res => {
          let data = res.data;
          state.user = data.user;
          state.order = data.order;
          state.order_earnings = data.order_earnings;
          state.cart = data.cart;
        });
        setCurrentPageTitle("Sipariş Detay");
      });


      return {
        route,
        user,
        order,
        cart,
        order_earnings,
        generalSettings: computed(() => store.getters.getGeneralSettingsList),
        l,
        NumberFormat
      };
    },


    methods: {

      format_date(data) {
        if (data) {
          return moment(String(data)).format('DD/MM/YYYY')
        }
      }, 

      status_o(data) {
        if (data == 'on delivery') {
          return 'Teslimatta'
        } else if (data == 'completed') {
          return 'Tamamlandı'
        } else if (data == 'pending') {
          return 'Bekliyor'
        } else if (data == 'processing') {
          return 'İşleme Alındı'
        } else {
          return 'Reddedildi'
        }
      },

      classStatus_o(data) {
        if (data == 'on delivery') {
          return 'badge-light-primary'
        } else if (data == 'completed') {
          return 'badge-light-success'
        } else if (data == 'pending') {
          return 'badge-light-warning'
        } else if (data == 'processing') {
          return 'badge-light-secondary'
        } else {
          return 'badge-light-danger'
        }
      },
    },
  });
